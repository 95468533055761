<template>
  <div class="roadmap">
    <Header/>
    <div class="main">
      <div class="title">OUR ROADMAP</div>
      <div class="content">
        <div class="group">
          <div class="season">
            <p>Season 1:</p>
            <p>The Era of Cultivation</p>
          </div>
          <div class="text">
            <p>- Responsible for cultivating it’s land and it’s people.</p>
            <p>- Unravel the entire land space of the Meta-Utopia.</p>
          </div>
          <div class="quarter">
            <div class="item">
              <div class="qTit">Q1 2022</div>
              <div class="qCon">
                <div class="li">
                  <div class="icon">◆</div>
                  <div class="date">14.02.2022</div>
                  <div class="info">Launch of White paper V1.0</div>
                </div>
                <div class="li">
                  <div class="icon">◆</div>
                  <div class="date">17.03.2022</div>
                  <div class="info">Launch of Meta-Utopia.io</div>
                </div>
                <div class="li">
                  <div class="icon">◆</div>
                  <div class="date">18.03.2022</div>
                  <div class="info">Amaurot entering the The SANDBOX Metaverse</div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="qTit">Q2 2022</div>
              <div class="qCon">
                <div class="li">
                  <div class="icon">◆</div>
                  <div class="date">01.04.2022</div>
                  <div class="info">Launch of White paper V1.1</div>
                </div>
                <div class="li">
                  <div class="icon">◆</div>
                  <div class="date">13.05.2022</div>
                  <div class="info">First LAND parcel in The SANDBOX</div>
                </div>
                <div class="li">
                  <div class="icon">◆</div>
                  <div class="date">22.05.2022</div>
                  <div class="info">Airdrop SAND tokens to LAND holder</div>
                </div>
                <div class="li">
                  <div class="icon">◆</div>
                  <div class="date">26.05.2022</div>
                  <div class="info">Launch of Meta-Utopia Amaurot Game Design Demo</div>
                </div>
                <div class="li">
                  <div class="icon">◆</div>
                  <div class="date">04.06.2022</div>
                  <div class="info">Launch of Meta-Utopia Angels</div>
                </div>
                <div class="li">
                  <div class="icon">◆</div>
                  <div class="date">25.06.2022</div>
                  <div class="info">Launch of Meta-Utopia Capital Amaurot in Game Maker Fund, The SANDBOX game</div>
                </div>
                <div class="li">
                  <div class="icon">◆</div>
                  <div class="date">01.07.2022</div>
                  <div class="info">Twitter reached 10,000 followers</div>
                </div>
                <div class="li">
                  <div class="icon">◆</div>
                  <div class="date">29.07.2022</div>
                  <div class="info">Launch of Meta-Utopia official website 2.0</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="group">
          <div class="season">
            <p>Season 2:</p>
            <p>The Era of Building and Development</p>
          </div>
          <div class="text">
            <p>- DAO is formed.</p>
            <p>- More tribes to be formed.</p>
            <p>- Final blueprint of the Meta-Utopia announced at the end of the season.</p>
          </div>
        </div>
        <div class="group">
          <div class="season">
            <p>Season 3:</p>
            <p>The Era of the Future: MetaFI</p>
          </div>
          <div class="text">
            <p>- Integration of artificial intelligence to NFTs (Non-Fungible Tokens).</p>
            <p>- The key to MetaFI is finally unlocked. </p>
            <p>- Running independently through the DAO and the DeFI protocols. </p>
            <p>- The entire Meta-Utopia to become the world’s most desired location in the Metaverse.</p>
          </div>
        </div>
      </div>
    </div>
    <Follow/>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Follow from "@/components/Follow";
import Footer from "@/components/Footer";

export default {
  name: "Roadmap",
  components: { Header, Follow, Footer }
}
</script>

<style lang="less" scoped>
.roadmap{
  .main{
    margin-top: 49px;
    position: relative;
    color: #fff;
    background-color: #12191c;
    padding: 35px 27px;
    text-align: left;
    .title{
      font-family: Montserrat-Bold, Verdana;
      font-size: 16px;
      font-weight: bold;
      line-height: 23px;
      background-image: -webkit-linear-gradient(top, #0bedad, #0ba191);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 20px;
    }
    .content{
      .group{
        margin-bottom: 30px;
        .season{
          font-family: Montserrat-Bold, Verdana;
          font-size: 16px;
          font-weight: bold;
          line-height: 23px;
          text-transform: uppercase;
          color: #DC155C;
          margin-bottom: 10px;
        }
        .text{
          font-family: Montserrat-Regular, Verdana;
          font-size: 12px;
          line-height: 20px;
          color: #C3C3C3;
        }
        .quarter{
          margin-top: 20px;
          .item{
            margin-bottom: 10px;
            .qTit{
              font-family: Montserrat-Bold, Verdana;
              font-size: 16px;
              font-weight: bold;
              line-height: 23px;
              margin-bottom: 10px;
            }
            .qCon{
              .li{
                display: flex;
                font-family: Montserrat-Regular, Verdana;
                font-size: 12px;
                line-height: 20px;
                color: #C3C3C3;
                .icon{
                  color: #05A990;
                }
                .date{
                  margin: 0 20px 0 10px;
                }
              }
            }
          }
          .item:last-child{
            margin-bottom: 0;
          }
        }
      }
      .group:last-child{
        margin-bottom: 0;
      }
    }
  }
}
</style>